import React from 'react';

let isFrench = process.env.GATSBY_LANG === 'FR';

export default function AboutUs() {
	return (
		<div className="my-14 grid grid-cols-3 border-[#D1D1D1] py-8 max-md:my-8 max-md:grid-cols-1 max-md:justify-items-center md:border-y">
			<div className="flex w-full flex-col items-center justify-start border-[#D1D1D1] max-md:max-w-[300px] max-md:border-y max-md:py-3 md:border-r">
				<div className="mx-auto w-56">
					<p className="text-[75px] font-medium leading-none tracking-tight text-pri">
						{isFrench ? '6,000+' : '6,000+'}
					</p>
					<p className="text-3xl font-normal uppercase text-pri">
						{isFrench ? 'Employés' : 'Employees'}
					</p>
				</div>
			</div>
			<div className="flex w-full flex-col items-center justify-start border-[#D1D1D1] max-md:max-w-[300px] max-md:border-b max-md:py-3 md:border-r">
				<div className="mx-auto w-56">
					<p className="text-[75px] font-medium leading-none tracking-tight text-pri">
						{isFrench ? '$500m' : '$500m'}
					</p>
					<p className="text-3xl font-normal text-pri">
						{isFrench ? '(CDN)' : '(CDN)'}
					</p>
					<p className="text-xl font-semibold leading-loose text-black">
						{isFrench ? "de chiffres d'affaires annuel" : 'annual revenue'}
					</p>
				</div>
			</div>
			<div className="flex w-full flex-col items-center justify-start border-[#D1D1D1] max-md:max-w-[300px]  max-md:border-b max-md:py-3">
				<div className="mx-auto w-56">
					<p className="text-[75px] font-medium leading-none tracking-tight text-pri">
						{isFrench ? '3,700' : '3,700'}
					</p>
					<p className="text-3xl font-normal text-pri">
						{isFrench ? 'VÉHICULES' : 'VEHICLES'}
					</p>
				</div>
			</div>
		</div>
	);
}
