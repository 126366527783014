import React from 'react';
import Layout from '../../layouts/Layout';
import { graphql, useStaticQuery } from 'gatsby';

import AboutUs from '../../components/AboutUs/AboutUs';
import ContactUs from '../../components/ContactUs/ContactUs';

import searchHero from '../../images/search-page-hero.png';

import transdevLogo from '../../images/transdev-logo-red.png';
import transdevLogoFrench from '../../images/transdev-logo-red-french.png';
import locationIcon from '../../images/location-icon.svg';
import arrowRight from '../../images/arrow-right-w.svg';

let isFrench = process.env.GATSBY_LANG === 'FR';

const generalPositions = [
	{
		name: 'Transdev Canada',
		text: isFrench
			? 'Soumettez une application générale à nos sièges nationaux.'
			: 'Submit a general application at our national headquarters.',
		href: isFrench
			? 'https://transdev.hiringplatform.ca/145899-transdev-canada-general-application/594401-application/fr_CA'
			: 'https://transdev.hiringplatform.ca/145899-transdev-canada-general-application/594401-application/en'
	},
	{
		name: 'Transdev Québec',
		text: isFrench
			? 'Soumettez une candidature générale au sein de nos opérations au Québec.'
			: 'Submit a general application within our Quebec operations.',
		href: isFrench
			? 'https://transdev.hiringplatform.ca/145595-transdev-quebec-candidature-spontanee/592851-application/fr_CA'
			: 'https://transdev.hiringplatform.ca/145595-transdev-quebec-candidature-spontanee/592851-application/en'
	},
	{
		name: 'Transdev West',
		text: isFrench
			? "Soumettez une candidature générale dans le cadre de nos opérations à l'Ouest."
			: 'Submit a general application within our Western operations.',
		href: isFrench
			? 'https://transdev.hiringplatform.ca/145901-transdev-west-general-application/594407-application/fr_CA'
			: 'https://transdev.hiringplatform.ca/145901-transdev-west-general-application/594407-application/en'
	},
	{
		name: 'Rail',
		text: isFrench
			? 'Soumettez une candidature générale au sein de notre équipe ferroviaire en Ontario.'
			: 'Submit a general application within our Rail team in Ontario.',
		href: isFrench
			? 'https://transdev.hiringplatform.ca/145898-transdev-rail-general-application/594398-application/fr_CA'
			: 'https://transdev.hiringplatform.ca/145898-transdev-rail-general-application/594398-application/en',
		linkText: 'Transdev Rail'
	}
];

const GeneralPage = () => {
	let data = useStaticQuery(graphql`
		query {
			allSitePage {
				edges {
					node {
						pageContext
						id
					}
				}
			}
		}
	`)?.allSitePage?.edges;

	return (
		<Layout
			title={'Transdev Careers'}
			desc={'Transdev Careers'}
			altLangPath="/general/"
		>
			<section className="bg-white">
				<div className="flex items-center justify-center bg-pri max-xl:flex-col-reverse">
					<div className="flex flex-col gap-2 px-14 pb-10 text-white max-md:px-6">
						<a
							className={
								'mb-10 inline-flex w-fit items-center gap-3 pb-2.5 text-xl font-medium text-white hover:opacity-90 max-xl:mt-6'
							}
							href={isFrench ? '/recherche/' : '/search/'}
						>
							<img src={arrowRight} className={'rotate-180'} />
							{isFrench ? 'Retour' : 'Back'}
						</a>
						<p className="text-base font-medium leading-tight">
							{isFrench ? "OFFRES D'EMPLOI" : 'JOB OFFERS'}
						</p>
						<h1 className="text-5xl font-bold">Transdev Canada</h1>
						<h1 className="text-xl leading-tight">
							{isFrench
								? `Vous souhaitez participer activement à la construction d’une mobilité innovante en rejoignant notre Groupe ? Renseignez-vous sur nos entreprises et obtenez des informations sur les offres d’emploi actuelles.`
								: `Would you like to take an active part in building
							innovative mobility by joining our Group? Find out
							about our businesses and obtain information on
							current job offers.`}
						</h1>
					</div>
					<img
						src={searchHero}
						width={657}
						height={371}
						alt="Search Hero"
						className="object-cover max-xl:max-h-[260px] max-xl:w-full"
					/>
				</div>
			</section>

			<section className={'bg-white py-10'}>
				<div
					className={
						'container mx-auto mt-6 flex flex-col gap-6 px-14 max-md:px-6'
					}
				>
					{generalPositions?.map((pos) => {
						return (
							<a
								href={pos.href}
								className={[
									'flex w-full cursor-pointer flex-col items-center justify-between gap-4 rounded-lg bg-white p-4 py-5 text-left text-sm font-medium text-body shadow-3xl hover:shadow-lg focus:outline-none focus:ring-4 focus:ring-blue-300 lg:flex-row lg:px-12 lg:py-8'
								].join(' ')}
							>
								<div className="flex items-center gap-6 max-sm:flex-col">
									<img
										src={
											isFrench
												? transdevLogoFrench
												: transdevLogo
										}
										width={120}
										height={35}
										className="h-fit"
									/>
									<div className={'flex flex-col gap-[14px]'}>
										<span
											className={
												'text-[26px] font-semibold text-pri'
											}
										>
											{pos.name}
										</span>
										<p
											className={
												'flex max-w-[500px] items-start gap-2 text-[16px] leading-tight text-body max-xl:w-full max-xl:max-w-[350px]'
											}
										>
											<img
												src={locationIcon}
												alt="Location Icon"
												className="pt-[1px]"
											/>
											{pos.text}
										</p>
									</div>
								</div>
								<div
									className={
										'flex h-min items-center justify-center gap-7 max-lg:mt-6 max-sm:flex-col'
									}
								>
									<button
										className={
											'mx-auto h-min w-fit bg-pri px-10 py-3 text-xl font-normal text-white transition-all hover:bg-[#940021] hover:opacity-90 focus:opacity-90'
										}
									>
										{isFrench
											? 'Postuler ici'
											: 'Apply Here'}
									</button>
								</div>
							</a>
						);
					})}
				</div>
			</section>
			<section className="bg-white">
				<div className="container mx-auto w-full max-w-4xl px-4 pb-11 pt-10">
					<div className="flex flex-col gap-[28px]">
						<h3 className="text-[28px] font-bold leading-[0.8] text-black">
							{isFrench
								? 'À Propos de Transdev Canada'
								: 'About Transdev Canada'}
						</h3>
						<p className="text-2xl leading-snug">
							{isFrench
								? `Opérateur et intégrateur mondial de la mobilité, Transdev permet à chacun de se déplacer au quotidien grâce à des solutions sûres, efficaces et innovantes au service du bien commun. Transdev transporte en moyenne 11 millions de passagers chaque jour grâce à ses différents modes de transport efficaces et respectueux de l’environnement, qui relient les individus et les communautés. En 2023, présent dans 6 provinces et un territoire, Transdev Canada compte 4700 employés œuvrant dans les secteurs du transport urbain, scolaire, adapté et médical. Transdev est également l’exploitant du futur tramway Hurontario dans la région du Grand Toronto, en tant que membre du consortium Mobilinx, et du futur métro Ontario Line en tant que membre du Connect 6ix.`
								: `As a global mobility operator and integrator,
							Transdev allows everyone to get around every day
							thanks to safe, efficient and innovative solutions
							that serve the common good. Transdev carries an
							average of 11 million passengers every day thanks to
							its various efficient and environmentally-friendly
							modes of transport, which connect individuals and
							communities. In 2023, present in 6 Provinces and one
							Territory, Transdev Canada has 4700 employees
							operating in the urban, school, paratransit and
							medical transportation sectors. Transdev is also the
							operator of the future Hurontario tramway in the
							Greater Toronto Area, as a member of the Mobilinx
							consortium, and of the future Ontario Line subway as
							a member of the Connect 6ix.`}
						</p>
					</div>
					<AboutUs />
				</div>
			</section>
			<ContactUs />
		</Layout>
	);
};

export default GeneralPage;
